import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface deleteMenuParam {
    code?:any
}

export interface syncParam {
    routes?:any
}

export interface orderParam {
    code?:any
    order?:any
}
// -------接口列------

/**
 * [后台]菜单信息
 * @notes 
 */
export function loadMenus() {
    return get('/system/menu/loadMenus');
}

/**
 * [后台]同步路由信息
 * @notes 
 */
export function sync(param:syncParam) {
    return post('/system/menu/sync', param);
}

/**
 * [后台]删除菜单
 * @notes 
 */
export function deleteMenu(param:deleteMenuParam) {
    return get('/system/menu/deleteMenu', param);
}

/**
 * [后台]设置排序
 * @notes 
 */
export function order(param:orderParam) {
    return get('/system/menu/order', param);
}

