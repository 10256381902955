
import { page, removeRole, status } from '@/api/system/role';
import JtDatePicker from '@/components/JtDatePicker/index.vue';
import JtPagination from '@/components/JtPagination/index.vue';
import { Component,Vue } from 'vue-property-decorator'
import ChangeRole from './components/ChangeRole.vue';
import MenuBind from './components/MenuBind.vue';
import PermissionBind from './components/PermissionBind.vue';
  

@Component({
    name: 'Role',
    components: {
        JtPagination,
        ChangeRole,
        JtDatePicker,
        PermissionBind,
        MenuBind
    }
})
export default class extends Vue {
  infoParams = {}
  dialogUpdateVisible = false
  dialogPermissionVisible = false
  dialogMenuVisible = false
  isEdit = false
  tableKey :any
  listLoading = true
  total = 0
  roleInfo:any = {}
  roleId = null
  downloading = false
  list = []
  listQuery = {
    current: 1,
    size: 10,
    enable: null,
    startTime: null,
    keyword: null,
    endTime: null
  }

  mounted() {
      this.getList()
  }
  //初始加载数据
  handleFilter() {
    this.listQuery.current = 1
    this.getList()
  }

  //加载Role数据
  getList(){
    this.listLoading = true
    page(this.listQuery).then(response => {
        this.list = response.data.rows
        this.total = response.data.total
        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      })
  }

  handleModifyStatus(row:any){
    this.$confirm('此操作将【' + (row.enable ? '停用' : '恢复') + '】当前角色, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      status(row).then(response => {
        const { enable } = response.data
        row.enable = enable
        this.$message({
            type: 'success',
            message: '操作成功!'
          });
      }).catch(()=>{})
    }).catch(() => {
      this.$message({
            type: 'info',
            message: '已取消当前操作'
          });      
    });
  }

  handleRemoveRole(row:any){
    this.$confirm('此操作将删除当前角色, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      removeRole(row).then(response => {
        this.handleFilter()
        this.$message({
            type: 'success',
            message: '操作成功!'
          });
      }).catch(()=>{})
    }).catch(() => {
      this.$message({
            type: 'info',
            message: '已取消当前操作'
      });      
    });
  }

  handlePermissionRole(row:any){
    this.roleInfo = row
    this.dialogPermissionVisible = true
  }

  handleAddRole(){
    this.isEdit = false
    this.infoParams = {}
    this.dialogUpdateVisible = true
  }

  handleEditRole(row:any){
    this.infoParams = row
    this.isEdit = true
    this.dialogUpdateVisible = true
  }

  handleMenu(row:any){
    this.roleId = row.id
    this.dialogMenuVisible = true
  }
}
