
import { locadPermissions, updatePermissions } from '@/api/system/apis';
import { list } from '@/api/system/serviceModule';
import JtPagination from '@/components/JtPagination/index.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
    name: 'PermissionBind',
    components: {
      JtPagination
    }
})
export default class PermissionBind extends Vue {
    @Prop({ required: true, default: false }) dialogPermissionVisible!: boolean;
    @Prop({ required: true }) readonly roleInfo!:any;
    
    serviceModules:Array<string> = []
    listLoading = true
    total = 0
    list = []
    listQuery = {
        current: 1,
        size: 10,
        roleId: 0,
        keyword: null,
        serviceName: null
    }
    dialogTitle = "绑定权限"


    //加载api数据
    getList(){
        this.listLoading = true
        locadPermissions(this.listQuery).then(response => {
            this.list = response.data.rows
            this.total = response.data.total
            // Just to simulate the time of the request
            setTimeout(() => {
            this.listLoading = false
            }, 500)
        })
    }

    get dialogVisible(){
      return this.dialogPermissionVisible;
    }

    set dialogVisible(val){
      this.$emit('update:dialogPermissionVisible', val)
    }

    //加载模块信息
    getModuleslist(){
        list().then(res =>{
        const { data } = res
        this.serviceModules = data.map((item:any)=>item.serviceId)
        }).catch()
    }

    //初始加载数据
    handleFilter() {
        this.listQuery.current = 1
        this.getList()
    }

    bindPermission(row:any,bind:boolean){
        this.$confirm('确定操作当前API资源?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                updatePermissions({
                    bind,
                    roleId: this.listQuery.roleId,
                    apiId: row.id
                }).then(() => {
                    row.selected = bind
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                }).catch(()=>{})
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消当前操作'
                });      
            });
    }
    
    @Watch("roleInfo")
    infoParamsChange(){
        this.listQuery.roleId = this.roleInfo.id
        this.dialogTitle = "绑定" + this.roleInfo.roleCode + "权限-【" + this.roleInfo.roleName + "】"
        this.getModuleslist()
        this.getList()
    }
}
