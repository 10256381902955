
import { addRole, updateRole } from '@/api/system/role';
import { cloneDeep } from '@/utils/common';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
    name: 'ChangeRole'
})
export default class ChangeRole extends Vue {
    @Prop({ required: true, default: false }) dialogUpdateVisible!: boolean;
    @Prop({ required: true, default: false }) readonly isEdit!:boolean;
    @Prop({ 
      required: false, 
      default: () => {
        return {
        id: null,
        roleName: null,
        roleCode: null,
        description: null,
        sort: null,
        enable: null
        }
      }
    }) 
    readonly infoParams!: any;
    
    get dialogVisible(){
      return this.dialogUpdateVisible;
    }

    set dialogVisible(val){
      this.$emit('update:dialogUpdateVisible', val)
    }
     
    formParam = {
      id: null,
      roleName: null,
      roleCode: null,
      description: null,
      sort: null,
      enable: null
    }

    get infoTitle(){
        if(this.isEdit){
            return "编辑角色-【" + this.formParam.roleName + "】";
        }else{
            return "添加角色";
        }
    }

    @Emit("getList")
    getList(){}

    handleUpdateRole(){
        if(this.isEdit){
            updateRole(this.formParam).then(res => {
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
                this.dialogVisible = false
                this.getList();
            })
        }else{
            addRole(this.formParam).then(res=>{
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
                this.dialogVisible = false
                this.getList();
            })
        }
    }

    @Watch("infoParams")
    infoParamsChange(){
        this.formParam = cloneDeep(this.infoParams)
    }
}
