import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface removeRoleParam {
    id?:any
}

export interface updateRoleParam {
    enable?:any
    roleCode?:any
    roleName?:any
    description?:any
    id?:any
    sort?:any
}

export interface pageParam {
    current?:any
    size?:any
    enable?:any
    startTime?:any
    endTime?:any
    keyword?:any
}

export interface addRoleParam {
    enable?:any
    roleCode?:any
    roleName?:any
    description?:any
    sort?:any
}

export interface statusParam {
    id?:any
}
// -------接口列------

/**
 * [后台]变更角色信息
 * @notes 
 */
export function updateRole(param:updateRoleParam) {
    return post('/system/role/updateRole', param);
}

/**
 * [后台]变更角色状态
 * @notes 
 */
export function status(param:statusParam) {
    return get('/system/role/status', param);
}

/**
 * [后台]加载所有角色信息
 * @notes 
 */
export function loadAllRoles() {
    return get('/system/role/loadAllRoles');
}

/**
 * [后台]添加角色信息
 * @notes 
 */
export function addRole(param:addRoleParam) {
    return post('/system/role/addRole', param);
}

/**
 * [后台]删除角色信息
 * @notes 
 */
export function removeRole(param:removeRoleParam) {
    return get('/system/role/removeRole', param);
}

/**
 * [后台]API分页
 * @notes 
 */
export function page(param:pageParam) {
    return get('/system/role/page', param);
}

