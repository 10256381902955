
  import { Component, Prop, Vue } from 'vue-property-decorator'
  
  @Component({
    name: 'JtDatePicker'
  })
  export default class JtDatePicker extends Vue {
    @Prop({ required: false }) startTime!: string
    @Prop({ required: false }) endTime!: string
    @Prop({ required: false, default: '开始日期' }) startPlaceholder!: string
    @Prop({ required: false, default: '结束日期' }) endPlaceholder!: string


    pickerOptions = {
      shortcuts: [{
        text: '最近一周',
        onClick(picker:any) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          picker.$emit('pick', [start, end]);
        }
      }, {
        text: '最近一个月',
        onClick(picker:any) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          picker.$emit('pick', [start, end]);
        }
      }, {
        text: '最近三个月',
        onClick(picker:any) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          picker.$emit('pick', [start, end]);
        }
      }]
    }

    get timeValue(){
      const data = []
      if(this.startTime && this.endTime){
        data.push(this.startTime)
        data.push(this.endTime)
      }
      return data
    }

    set timeValue(val:any){
      if(!val || val.length === 0){
        this.$emit('update:startTime', null)
        this.$emit('update:endTime', null)
      }else{
        this.$emit('update:startTime', val[0])
        this.$emit('update:endTime', val[1])
      }
    }
  }
  