import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface deleteApiParam {
    id?:any
}

export interface locadPermissionsParam {
    current?:any
    size?:any
    roleId?:any
    keyword?:any
    serviceName?:any
}

export interface pageParam {
    current?:any
    size?:any
    active?:any
    keyword?:any
    serviceName?:any
}

export interface downLoadApisParam {
    current?:any
    size?:any
    active?:any
    keyword?:any
    serviceName?:any
}

export interface updatePermissionsParam {
    bind?:any
    roleId?:any
    apiId?:any
}
// -------接口列------

/**
 * [后台]删除API接口信息
 * @notes 
 */
export function deleteApi(param:deleteApiParam) {
    return get('/system/apis/deleteApi', param);
}

/**
 * [后台]API分页
 * @notes 
 */
export function locadPermissions(param:locadPermissionsParam) {
    return get('/system/apis/locadPermissions', param);
}

/**
 * [后台]更新角色的权限信息
 * @notes 
 */
export function updatePermissions(param:updatePermissionsParam) {
    return post('/system/apis/updatePermissions', param);
}

/**
 * [后台]API同步
 * @notes 
 */
export function sync() {
    return post('/system/apis/sync');
}

/**
 * [后台]下载Apis
 * @notes 
 */
export function downLoadApis(param:downLoadApisParam) {
    return downloadG('/system/apis/downLoadApis', param);
}

/**
 * [后台]API分页
 * @notes 
 */
export function page(param:pageParam) {
    return get('/system/apis/page', param);
}

