
  import { Component, Prop, Vue } from 'vue-property-decorator'
  
  @Component({
    name: 'JtPagination'
  })
  export default class JtPagination extends Vue {
    @Prop({ required: true }) total!: Number
    @Prop({ required: true, default: 1 }) page!: Number
    @Prop({ default: 10 }) limit!: Number
    @Prop({ default: () => [10, 20, 30, 50] }) pageSizes!: Array<Number>
    @Prop({ default: 'total, sizes, prev, pager, next, jumper' }) layout!: String

    get currentPage(){
        return this.page
    }

    set currentPage(val){
        this.$emit('update:page', val)
    }

    get pageSize(){
        return this.limit
    }

    set pageSize(val){
        this.$emit('update:limit', val)
    }
  
    handleSizeChange(val:Number) {
      this.$emit('pagination', { page: this.currentPage, limit: val })
    }
    handleCurrentChange(val:Number) {
      this.$emit('pagination', { page: val, limit: this.pageSize })
    }
  }
  