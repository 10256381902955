
import { loadMenus } from '@/api/system/menu';
import { bindMenu, loadBind } from '@/api/system/roleMenu';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
    name: 'MenuBind'
})
export default class MenuBind extends Vue {
    @Prop({ required: true, default: false }) dialogMenuVisible!: boolean;
    @Prop({ required: true }) readonly roleId!:any;
    
    listLoading = true
    list = []
    multipleSelection = []
    cloudSelection = []

    //加载api数据
    getMenuList(){
        this.listLoading = true
        loadMenus().then(response => {
            this.list = response.data
            this.loadBindData()
        })
    }

    get dialogVisible(){
      return this.dialogMenuVisible;
    }

    set dialogVisible(val){
      this.$emit('update:dialogMenuVisible', val)
    }

    @Emit("getList")
    getList(){}

    handleUpdate(){
        this.$confirm('确定当前更改?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                bindMenu({
                    roleId: this.roleId,
                    codes: this.multipleSelection
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                    this.dialogVisible = false
                    this.getList();
                }).catch(()=>{})
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消当前操作'
                });      
            });
    }
    
    @Watch("roleId")
    roleIdChange(){
        this.getMenuList()
    }

    //处理是否可用多选
    handleSelectable(row:any, index:any){
        return row.last
    }

    handleSelectionChange(val:any) {
        this.multipleSelection = val.map((rd:any) => rd.code);
    }

    //初始化选中项
    initSelection() {
        if(this.list.length > 0){
            this.list.forEach(item => {
                this.selectedInit(item)
            })
        }
        setTimeout(() => {
            this.listLoading = false
        }, 500)
    }

    selectedInit(item:any){
        const rows = this.cloudSelection.filter((rd:string) => item.code === rd).length
        if(rows > 0){
            const multipleTable:any = this.$refs.multipleTable
            multipleTable.toggleRowSelection(item)
        }
        if(item.children && item.children.length > 0){
            item.children.forEach((row: any) => {
                this.selectedInit(row)
            })
        }
    }

    loadBindData(){
        loadBind({ roleId: this.roleId }).then(response => {
            this.cloudSelection = response.data
            // Just to simulate the time of the request
            this.initSelection()
        })
    }
}
