import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface bindMenuParam {
    codes?:any
    roleId?:any
}

export interface loadBindParam {
    roleId?:any
}
// -------接口列------

/**
 * [后台]加载绑定信息
 * @notes 
 */
export function loadBind(param:loadBindParam) {
    return get('/system/roleMenu/loadBind', param);
}

/**
 * [后台]绑定菜单
 * @notes 
 */
export function bindMenu(param:bindMenuParam) {
    return post('/system/roleMenu/bindMenu', param);
}

