import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------
// -------接口列------

/**
 * [后台]加载模块
 * @notes 
 */
export function list() {
    return get('/system/serviceModulePo/list');
}

